






import { Vue, Component, Mixins } from 'vue-property-decorator'

import MyCommon from '@/mixins/common'

import EasyTyper from 'easy-typer-js'

import icon from '@/assets/icon'

@Component
export default class extends Mixins(MyCommon) {
  roboticon: string = icon.robot.replace('image://', '')

  output = ''

  mounted() {
    const obj: any = {
      output: '',
      type: 'normal',
      isEnd: false,
      speed: 8,
      backSpeed: 40,
      sleep: 0,
      singleBack: false,
      sentencePause: false,
    }
    // 数组模式 根据顺序一句一句输出
    const inputArr: string[] = [
      `黎明前的黑暗是最深不见底的黑暗！`,
      `世界上本没有无用的齿轮，只有齿轮自身能决定它的用途！`,
      `天不生我彭小呆，万古长青一生狂！`,
    ]
    const typing = new EasyTyper(
      obj,
      inputArr,
      () => {
        console.log('结束了，我的使命！')
      },
      (output: any, instance: any) => {
        this.output = output
      }
    )
  }
}
