/**
 * [rain description]
 * @param  {[Element]} canvas canvas元素对象
 * @param  {[String]} text    掉落的字符串
 * @param  {[String]} symbol 分隔符
 * @param  {[Number]} speed 掉落速度
 * @return {[type]}        [description]
 */
export const rain = (
  canvas: any,
  text: string,
  symbol: string,
  speed: number
) => {
  //获取屏幕可视区域大小
  let d = document.documentElement
  let clinetW = d.clientWidth
  let clinetH = d.clientHeight

  //设置画布大小
  canvas = canvas || document.querySelector('canvas')
  canvas.width = clinetW
  canvas.height = clinetH

  let cxt = canvas.getContext('2d')
  let rainStr = text || 'The matrix of hackers'
  symbol = symbol || ''
  let arr = rainStr.split(symbol)

  let fontSize = 14
  // 计算行数
  let cols = Math.floor(clinetW / fontSize)
  // 初始化Y轴坐标
  let down: number[] = []
  for (let i = 0; i < cols; i++) {
    down.push(Math.floor(Math.random() * -100))
  }

  function drawRain() {
    // 填充背景(ps:背景采用rgba,可尝试不同透明度的效果)
    cxt.fillStyle = 'rgba(8,5,66,0.1)'
    cxt.fillRect(0, 0, clinetW, clinetH)

    // 设置字体颜色
    cxt.fillStyle = '#00ff00'
    for (let i = 0; i < down.length; i++) {
      let randomNum = Math.random()
      // 绘制文字
      cxt.fillText(
        arr[Math.floor(randomNum * arr.length)],
        i * fontSize,
        down[i] * fontSize
      )

      if (down[i] * fontSize > clinetH && randomNum > 0.9) {
        down[i] = 0
      }

      down[i]++
    }
  }
  speed = speed || 30
  setInterval(drawRain, speed)
}
