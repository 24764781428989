

























import { Vue, Component, Mixins } from 'vue-property-decorator'

import _ from 'lodash'
import moment from 'moment'
// @ts-ignore
import numeral from 'numeral'

import {
  use,
  registerMap,
  registerTheme,
  connect,
  disconnect,
} from 'echarts/core'
import {
  BarChart,
  LineChart,
  PieChart,
  MapChart,
  RadarChart,
  ScatterChart,
  EffectScatterChart,
  LinesChart,
  GraphChart,
} from 'echarts/charts'
import {
  GridComponent,
  PolarComponent,
  GeoComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  VisualMapComponent,
  DatasetComponent,
  ToolboxComponent,
  DataZoomComponent,
  GraphicComponent,
} from 'echarts/components'

import { CanvasRenderer, SVGRenderer } from 'echarts/renderers'

import 'echarts-gl'

import VChart, { THEME_KEY } from 'vue-echarts'

import icon from '@/assets/icon'

import MyCommon from '@/mixins/common'

// @ts-ignore
import MyDvLoading from '$ui/dv/packages/my-dv-loading'
// @ts-ignore
import MyDvBox from '$ui/dv/packages/my-dv-box'

import Detail from './graph_detail_chart.vue'

@Component({
  components: {
    VChart,
    MyDvLoading,
    MyDvBox,
    Detail,
  },
  provide: {
    [THEME_KEY]: 'light',
  },
})
export default class extends Mixins(MyCommon) {
  ei: any

  t1: any
  t2: any

  page: number = 1
  total: number = 0
  totalPage: number = 1

  randomStr = ['AI订场', 'AI组局']

  playerDetailVisible: boolean = false
  userId: number = 0
  userName: string = ''
  userMobile: string = ''

  option: any = {
    title: {
      text: '小羽服务',
      subtext: '球友总数：人',
      textStyle: {
        color: '#ffffff',
      },
      subtextStyle: {
        color: '#ffffff',
      },
    },
    tooltip: {
      formatter: (x: any) => {
        return x.data.username
      },
    },
    grid: {
      top: '2%',
      left: '2%',
      right: '2%',
      bottom: '2%',
    },
    xAxis: {
      // max: 1880,
      show: false,
      type: 'value',
    },
    yAxis: {
      // min: 0,
      // max: 1000,
      show: false,
      type: 'value',
    },
    dataZoom: [{ type: 'inside' }, { type: 'inside', orient: 'vertical' }],
    series: [
      {
        name: '球友',
        type: 'graph',
        z: 2,
        coordinateSystem: 'cartesian2d',
        data: [],
        nodes: [],
        edges: [],
        links: [],
        roam: true,
        // focusNodeAdjacency: false,
        emphasis: {
          focus: 'adjacency',
          label: {
            show: false,
            position: 'right',
            formatter: (x: any) => {
              if (x.data.username === '小羽') {
                return '小羽'
              }
              return `${x.data.username}`
            },
            color: '#ffffff',
          },
        },
        lineStyle: {
          color: 'source',
          opacity: 0.05,
          curveness: 0.3,
          width: 1,
        },
        // label: {
        //   emphasis: {
        //     show: false,
        //     position: 'right',
        //     formatter: (x: any) => {
        //       if (x.data.username === '小羽') {
        //         return '小羽'
        //       }
        //       return `${x.data.username}`
        //     },
        //     color: '#ffffff',
        //   },
        // },
      },
      {
        zlevel: 2,
        z: 3,
        type: 'lines',
        coordinateSystem: 'cartesian2d',
        // effect: {
        //   show: true,
        //   trailLength: 0.02,
        //   symbolSize: 2,
        //   constantSpeed: 200,
        // },
        // // large: true,
        // lineStyle: {
        //   normal: {
        //     width: 0,
        //     curveness: 0.3,
        //   },
        // },
        effect: {
          show: true,
          trailWidth: 1,
          trailOpacity: 1,
          trailLength: 0.2,
          // constantSpeed: 100
        },

        blendMode: 'lighter',

        lineStyle: {
          width: 0.3,
          opacity: 0.3,
          type: 'dashed',
          curveness: 0.4,
        },
        data: [],
      },
      {
        // zlevel: 3,
        type: 'scatter',
        symbolSize: 2,
        // symbol: (value: any, params: any) => {
        //   // console.log(value);
        //   // console.log(params);
        //   return Number(value[4]) === 2 ? icon.female : icon.male;
        // },
        itemStyle: {
          color: '#1890ff',
          opacity: 0.4,
        },
        data: [],
        blendMode: 'lighter',
        large: true,
        largeThreshold: 500,
        // tooltip: {
        //   formatter: (params: any) => {
        //     return params.value[2];
        //   },
        // },
      },
    ],
  }

  showDetailModal() {
    this.playerDetailVisible = true
  }

  hideDetailModal() {
    this.playerDetailVisible = false
  }

  async mounted() {
    this.loading = true
    const chart: any = this.$refs.ei
    this.ei = chart.chart

    // this.ei.showLoading('default', {
    //   text: '加载中，请稍后',
    // })

    const res0 = await this.$store.dispatch('datav/userCount', {})
    if (res0 && res0.Data) {
      this.total = res0.Data
    }

    this.option.series[0].data = []
    this.option.series[0].edges = []

    let nodes: any[] = []
    const edges: any[] = []

    const res = await this.$store.dispatch('datav/graph', {})

    this.loading = false
    if (res && res.Data) {
      //   console.log(res.Data.nodes);
      //   for (const node of res.Data.nodes) {
      //     this.option.series[0].data.push({
      //       name: node.name
      //     });
      //   }
      const innerWidth: number = 1920
      const innerHeight: number = 1080
      // console.log(innerWidth);
      // console.log(innerHeight);

      nodes = res.Data.nodes.map((node: any) => {
        let size = (Number(node.ucount) / 50) * 20
        if (size > 15) {
          size = 15
        }
        node.username = this.renderUsername(node.mobile)
        node.symbolSize = size
        // node.fixed = true;
        node.x = this.randomX()
        node.y = this.randomY()
        // node.label = {
        //   normal: {
        //     show: node.ucount > 30,
        //     position: "right",
        //     color: "#fff",
        //     formatter: (x: any) => {
        //       return x.data.username;
        //     }
        //   }
        // };
        node.symbol =
          node.label === '小羽'
            ? icon.robot
            : Number(node.sex) === 2
            ? icon.female
            : icon.male
        node.itemStyle = {
          color:
            node.label === '小羽'
              ? '#ffffff'
              : Number(node.sex) === 2
              ? '#eb2f96'
              : '#1890ff',
        }
        node.value = [node.x, node.y]
        return Object.assign({}, node)
      })
      nodes.push({
        id: 'xx',
        username: '小羽',
        symbolSize: 30,
        x: this.randomX(),
        y: this.randomY(),
        symbol: icon.robot,
        itemStyle: { color: '#ffffff' },
        value: [500, 500],
        // name: this.randomStr[_.random(0, 1)],
        label: {
          show: true,
          position: 'right',
        },
      })
      this.option.series[0].data = nodes

      //   this.option.series[0].edges = res.Data.edges.map((edge: any) => {
      //     return Object.assign({}, edge);
      //   });
      const tempArr: string[] = []
      const coordsArr: any[] = []
      for (const obj of res.Data.edges) {
        if (
          _.indexOf(tempArr, `${obj.target}-${obj.source}`) === -1 &&
          _.indexOf(tempArr, `${obj.source}-${obj.target}`) === -1
        ) {
          tempArr.push(`${obj.source}-${obj.target}`)
          // edges.push(obj);
          // 查找source
          const source: any = _.find(this.option.series[0].data, (o: any) => {
            return o.id === obj.source
          })
          // 查找target
          const target: any = _.find(this.option.series[0].data, (o: any) => {
            return o.id === obj.target
          })
          if (source && target) {
            coordsArr.push([source.value, target.value])
          }
        }
      }

      // console.log(edges);

      // this.option.series[0].edges = this.option.series[0].links = edges;

      let myFn1: any
      this.t1 = window.setTimeout(
        (myFn1 = () => {
          // 取随机一个数
          const itm1: any =
            coordsArr[Math.floor(Math.random() * coordsArr.length)]
          // 小羽图标移动到这个点上
          this.option.series[0].data.map((obj: any) => {
            if (obj.id === 'xx') {
              obj.value = itm1[0]
              // obj.name = this.randomStr[_.random(0, 1)];
            }
            return obj
          })
          this.option.series[1].data = [itm1]
          const idx = _.findIndex(this.option.series[0].data, (o: any) => {
            return o.id == 'xx'
          })

          // console.log(idx);
          // console.log(this.option.series[0].data[idx]);

          const name = this.randomStr[_.random(0, 1)]

          window.setTimeout(() => {
            // console.log("显示name");
            this.option.series[0].data[idx].name = name
            // this.ei.setOption(this.option)
            window.setTimeout(() => {
              // console.log("隐藏name");
              this.option.series[0].data[idx].name = ''
              // this.ei.setOption(this.option)
            }, 500)
          }, 1000)

          // window.setTimeout(() => {
          //   this.ei.dispatchAction({
          //     type: "showTip",
          //     seriesIndex: 0,
          //     dataIndex: _.findIndex(this.option.series[0].data, (o: any) => {
          //       return o.id == "xx";
          //     }),
          //   });
          //   window.setTimeout(() => {
          //     this.ei.dispatchAction({
          //       type: "hideTip",
          //     });
          //   }, 500);
          // }, 1000);

          window.clearTimeout(this.t1)
          this.t1 = window.setTimeout(myFn1, 4000)
        }),
        4000
      )
      // this.ei.on('finished', () => {
      //   console.log('finished')
      // })

      //   this.option.series[0].links = res.Data.edges;
      //   console.log(this.option);
      // this.ei.on('click', (params: any) => {
      //   console.log(params)
      //   if (params.data.id) {
      //     this.userId = Number(params.data.id)
      //     this.userName = this.renderUsername(params.data.mobile)
      //     this.playerDetailVisible = true
      //   }

      //   // 显示球友详情页

      //   // if (params.dataType === "node") {
      //   //   this.ei.dispatchAction({
      //   //     type: "focusNodeAdjacency",
      //   //     seriesIndex: 0,
      //   //     dataIndex: params.dataIndex
      //   //   });
      //   // } else {
      //   //   this.ei.dispatchAction({
      //   //     type: "unfocusNodeAdjacency",
      //   //     seriesIndex: 0
      //   //   });
      //   // }
      // })

      // this.ei.on('mouseover', (params: any) => {
      //   // console.log(params);
      //   if (params.dataType === 'edge') {
      //     // this.ei.dispatchAction({
      //     //   type: "unfocusNodeAdjacency",
      //     //   seriesIndex: 0
      //     // });
      //   }
      // })

      // this.ei.on('highlight', (params: any) => {
      //   // console.log(params);
      // })
      // console.log(this.option);
      // this.ei.setOption(this.option)
      // this.ei.hideLoading()
      this.$nextTick(() => {
        // this.ei.resize();

        this.option.title.subtext =
          '球友总数：' + this.renderNumber(this.total) + '人'
      })
    }

    this.loadPageData(1)

    // 分页加载
    let myFn2: any
    this.t2 = window.setTimeout(
      (myFn2 = () => {
        // console.log(this.page);
        // console.log(this.totalPage);
        if (this.page >= 1) {
          window.clearTimeout(this.t2)
        } else {
          const curhour = moment().hour()
          if (!(1 <= curhour && curhour <= 5)) {
            // 凌晨1点到5点之间不要实时刷新数据
            this.loadPageData(this.page)
          }
          window.clearTimeout(this.t2)
          this.t2 = window.setTimeout(myFn2, 5000)
        }
      }),
      5000
    )
  }

  randomX() {
    return this._random() * 960
  }

  randomY() {
    return this._random() * 540
  }

  _random() {
    const max = 1
    const min = -1
    return Math.random() * (max - min + 1) + min
  }

  renderUsername(mobile: string) {
    return `用户${mobile.substring(7)}`
  }

  async loadPageData(page: number) {
    // 加载20万个点
    const res1 = await this.$store.dispatch('datav/userGraph', {
      page,
      page_size: 10000,
    })
    // console.log(res1);
    if (res1 && res1.Data) {
      this.page = res1.Data.page + 1
      // this.total = res1.Data.total;
      this.totalPage = res1.Data.total_page

      const data1: any[] = res1.Data.data.map((o: any) => {
        o.x = this.randomX()
        o.y = this.randomY()
        // o.symbolSize = 30;
        return [o.x, o.y, this.renderUsername(o.mobile), o.mobile, o.sex]
      })
      // console.log(data1);

      this.option.series[2].data = _.concat(this.option.series[2].data, data1)
      // this.ei.appendData({
      //   seriesIndex: 2,
      //   data: data1
      // });

      // console.log(this.ei.getOption());
      // console.log(this.option)
      // this.ei.on('finished', () => {
      //   console.log('finished2')
      // })
    }
  }

  handleChartClick(params: any) {
    console.log(params)
    if (params.data.id) {
      this.userId = Number(params.data.id)
      this.userName = this.renderUsername(params.data.mobile)
      this.userMobile = params.data.mobile
      this.playerDetailVisible = true
    }
  }

  beforeUnmount() {
    window.clearTimeout(this.t1)
    window.clearTimeout(this.t2)
  }
  beforeDestroy() {
    window.clearTimeout(this.t1)
    window.clearTimeout(this.t2)
  }
}
