










































































































































































import { Vue, Component, Mixins, Prop } from 'vue-property-decorator'

import _ from 'lodash'
import G6 from '@antv/g6'

import icon from '@/assets/icon'

import { rain } from '@/utils/canvas-rain'
// @ts-ignore
import insertCss from 'insert-css'
insertCss(`
  .g6-tooltip {
    border: 1px solid #e2e2e2;
    border-radius: 4px;
    font-size: 12px;
    color: #000;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 10px 8px;
    box-shadow: rgb(174, 174, 174) 0px 0px 10px;
  }
  #canvas-menu {
  position: absolute;
  left: 0.833vw;
  top: 0.833vw;
  width: fit-content;
  padding: 0.417vw 0.833vw;
  background-color: rgba(54, 59, 64, 0);
  border-radius: 1.25vw;
  box-shadow: 0 0.26vw 0.938vw 0 rgba(0, 0, 0, 0);
  font-family: PingFangSC-Semibold;
  transition: all 0.2s linear;
}
#canvas-menu:hover {
  background-color: rgba(54, 59, 64, 1);
  box-shadow: 0 0.26vw 0.938vw 0 rgba(0, 0, 0, 0.6);
}
.icon-span {
  padding-left: 0.417vw;
  padding-right: 0.417vw;
  cursor: pointer;
}
#search-node-input {
  background-color: rgba(60, 60, 60, 0.95);
  border-radius: 1.094vw;
  width: 5.208vw;
  border-color: rgba(80, 80, 80, 0.95);
  border-style: solid;
  color: rgba(255, 255, 255, 0.85);
}
#submit-button {
  background-color: rgba(82, 115, 224, 0.2);
  border-radius: 1.094vw;
  border-color: rgb(82, 115, 224);
  border-style: solid;
  color: rgba(152, 165, 254, 1);
  margin-left: 0.208vw;
}
.menu-tip {
  position: absolute;
  right: 0.833vw;
  width: fit-content;
  height: 2.083vw;
  line-height: 2.083vw;
  top: 0.833vw;
  padding-left: 0.833vw;
  padding-right: 0.833vw;
  background-color: rgba(54, 59, 64, 0.5);
  color: rgba(255, 255, 255, 0.65);
  border-radius: 0.417vw;
  transition: all 0.2s linear;
  font-family: PingFangSC-Semibold;
}
#g6-canavs-menu-item-tip {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.65);
  padding: 0.521vw;
  box-shadow: rgba(0, 0, 0, 0.6) 0px 0px 10px;
  width: fit-content;
  color: #fff;
  border-radius: 0.417vw;
  font-size: 0.625vw;
  height: fit-content;
  font-family: PingFangSC-Semibold;
  transition: all 0.2s linear;
}
`)

import MyCommon from '@/mixins/common'

// @ts-ignore
import MyDvBox from '$ui/dv/packages/my-dv-box'
// @ts-ignore
import MyDvBorder2 from '$ui/dv/packages/my-dv-border2'
// @ts-ignore
import MyDvLoading from '$ui/dv/packages/my-dv-loading'

import Robot from './graph_detail_robot.vue'

@Component({
  components: {
    MyDvBox,
    MyDvBorder2,
    MyDvLoading,
    Robot,
  },
})
export default class extends Mixins(MyCommon) {
  @Prop() userName!: string
  @Prop() userId!: string
  @Prop() userMobile!: string

  robotVisible: boolean = false

  num1: number = 0
  num2: number = 0
  num3: number = 0
  num4: number = 0

  roboticon: string = icon.robot.replace('image://', '')

  infoBox = false

  shiftKeydown = false

  graph: any = null

  colorSets: any

  result: string = ''

  action: string = ''

  canvasRainState: string = 'block'

  fishEye: any = null

  fisheyeEnabled = false

  clickFisheyeIcon(onlyDisable: any) {
    if (onlyDisable) {
      this.fisheyeEnabled = false
    } else {
      this.fisheyeEnabled = !this.fisheyeEnabled
    }
  }

  changeAction(act: string) {
    this.action = act
    if (act == 'minus') {
      this.handleZoomIn()
    } else if (act == 'plus') {
      this.handleZoomOut()
    } else if (act == 'fit') {
      this.handleFitView()
    } else if (act == 'eye') {
      this.toggleFishEye()
    }
  }

  handleZoomOut() {
    if (!this.graph || this.graph.destroyed) return

    const current = this.graph.getZoom()
    const canvas = this.graph.get('canvas')
    const point = canvas.getPointByClient(
      canvas.get('width') / 2,
      canvas.get('height') / 2
    )
    const pixelRatio = canvas.get('pixelRatio') || 1
    const ratio = 1 + 0.05 * 5
    if (ratio * current > 5) {
      return
    }

    this.graph.zoom(ratio, { x: point.x / pixelRatio, y: point.y / pixelRatio })
  }

  handleZoomIn() {
    if (!this.graph || this.graph.destroyed) return

    const current = this.graph.getZoom()
    const canvas = this.graph.get('canvas')
    const point = canvas.getPointByClient(
      canvas.get('width') / 2,
      canvas.get('height') / 2
    )
    const pixelRatio = canvas.get('pixelRatio') || 1
    const ratio = 1 - 0.05 * 5
    if (ratio * current < 0.3) {
      return
    }

    this.graph.zoom(ratio, { x: point.x / pixelRatio, y: point.y / pixelRatio })
  }

  handleFitView() {
    if (!this.graph || this.graph.destroyed) return

    this.graph.fitView(16)
  }

  handleSearchNode() {
    if (!this.graph || this.graph.destroyed) return
    // @ts-ignore
    const value = this.$refs.searchNodeInput.value
    console.log(value)

    const item = this.graph.findById(value)
    if (!item) return
    if (item && item.getType() !== 'node') return

    this.graph.focusItem(item, true)
    this.clearFocusItemState(this.graph)
    this.graph.setItemState(item, 'focus', true)
    return
  }

  toggleFishEye() {
    if (!this.graph || this.graph.destroyed) return

    this.graph.get('canvas').setCursor('default')

    if (this.fisheyeEnabled && this.fishEye) {
      this.graph.removePlugin(this.fishEye)

      this.graph.setMode('default')
    } else {
      // 关闭 lasso 框选
      // 关闭选择路径端点
      // 关闭搜索节点框

      this.graph.setMode('fisheyeMode')

      this.fishEye = new G6.Fisheye({
        r: 249,
        scaleRBy: 'wheel',
        minR: 100,
        maxR: 500,
      })

      this.graph.addPlugin(this.fishEye)
    }
  }

  generateColorSets() {
    // Generate color sets according to subject colors
  }

  beforeCreate() {
    // this.generateColorSets()
    const subjectColors = [
      '#fff', // blue
      'red',
      'blue',
      'yellow',
      'green',
    ]
    const backColor = '#fff'
    const theme = 'default'
    const disableColor = '#777'
    const colorSets = G6.Util.getColorSetsBySubjectColors(
      subjectColors,
      backColor,
      theme,
      disableColor
    )

    // console.log(colorSets)

    this.colorSets = colorSets
  }

  showRobot() {
    this.canvasRainState = 'block'
    this.clearFocusItemState(this.graph)
    // this.robotVisible = true
    const pathNode = this.graph.findById(this.userMobile)
    console.log(pathNode)
    if (pathNode) {
      pathNode.toFront()
      this.graph.setItemState(pathNode, 'focus', true)
      // this.graph.setItemState(pathNode, 'selected', true)

      // 将相关边也高亮
      const relatedEdges = pathNode.getEdges()
      // console.log(relatedEdges)
      relatedEdges.forEach((edge: any) => {
        this.graph.setItemState(edge, 'running', true)
      })

      // 一秒后操作下一级
      setTimeout(() => {
        const nodes = pathNode.getNeighbors('target')
        for (const node of nodes) {
          this.graph.setItemState(node, 'focus', true)

          // 将相关边也高亮
          const relatedEdges = node.getEdges()
          // console.log(relatedEdges)
          relatedEdges.forEach((edge: any) => {
            this.graph.setItemState(edge, 'running', true)
          })
        }
      }, 1000)
    }

    setTimeout(() => {
      this.result = '组局完成，等待用户问答'

      this.result = '组局完成，应答失败'

      this.result = '无法组局，原因：某某用户最近几天已打几场，组局成功率30%'

      // this.canvasRainState = 'none'
      this.clearFocusItemState(this.graph)
    }, 3000)
  }

  hideRobot() {
    this.robotVisible = false
  }

  categoryColor(obj: any) {
    let idx = 0
    switch (obj.type) {
      case '球友':
        idx = 1
        break
      case '社群球友':
        idx = 2
        break
      case '球友的球友':
        idx = 3
        break
      case '球友的社群球友':
        idx = 4
        break
      default:
        idx = 0
        break
    }

    return this.colorSets[idx]
  }

  renderUsername(mobile: string) {
    return `用户${mobile.substring(7)}`
  }

  hideInfoBox() {
    this.infoBox = !this.infoBox
  }

  async loadG6() {
    const width: number = Number(
      // @ts-ignore
      window.getComputedStyle(this.$refs.mountNode).width.replace('px', '')
    )

    const height: number = Number(
      // @ts-ignore
      window.getComputedStyle(this.$refs.mountNode).height.replace('px', '')
    )

    console.log(width)

    G6.registerEdge(
      'custom-line',
      {
        setState(name, value, item: any) {
          // console.log(name)
          const group = item.get('group')
          // @ts-ignore
          const shape = item.get('keyShape')
          const length = shape.getTotalLength()
          // console.log(shape)
          if (name === 'running') {
            // 边path的起点位置
            const startPoint = shape.getPoint(0)

            if (value) {
              // const model = item.getModel()

              // const arrow: any = model.style.endArrow

              // shape.attr({
              //   stroke: '#fff',
              //   endArrow: {
              //     stroke: '#fff',
              //     fill: '#fff',
              //   },
              // })
              const circle = group.find(
                (ele: any) => ele.get('name') === 'circle-shape'
              )
              // if(circle)
              if (circle) {
                circle.stopAnimate()
                circle.remove()
                circle.destroy()
              }
              // 添加一条边
              const { path, stroke, lineWidth } = shape.attr()
              const path1 = group.addShape('path', {
                attrs: {
                  path,
                  stroke: '#fff',
                  lineWidth,
                  opacity: 1,
                },
                name: 'circle-shape',
              })

              const length = shape.getTotalLength()

              path1.animate(
                (ratio: any) => {
                  const startLen = ratio * length

                  const cfg = {
                    lineDash: [startLen, length - startLen],
                  }

                  return cfg
                },
                {
                  repeat: true,
                  duration: 2000,
                }
              )

              // 添加一个点
              // const circle1 = group.addShape('circle', {
              //   attrs: {
              //     x: startPoint.x,
              //     y: startPoint.y,
              //     fill: 'red',
              //     r: 3,
              //   },
              //   name: 'circle-shape',
              // })

              // circle1.animate(
              //   (ratio: any) => {
              //     // const startLen = ratio * length

              //     // const cfg = {
              //     //   lineDash: [startLen, length - startLen],
              //     // }
              //     const tmpPoint = shape.getPoint(ratio)

              //     const cfg = {
              //       x: tmpPoint.x,
              //       y: tmpPoint.y,
              //     }

              //     return cfg
              //   },
              //   {
              //     repeat: true,
              //     duration: 3000,
              //   }
              // )
            } else {
              // circle.stopAnimate()
              const circle = group.find(
                (ele: any) => ele.get('name') === 'circle-shape'
              )
              // if(circle)
              if (circle) {
                circle.stopAnimate()
                circle.remove()
                circle.destroy()
              }

              // shape.attr('lineDash', null)
            }
          }
        },
      },
      'line'
    )
    this.graph = new G6.Graph({
      // @ts-ignore
      container: this.$refs.mountNode,
      width,
      height,
      modes: {
        default: [
          'zoom-canvas',
          {
            type: 'tooltip',
            formatText: (model: any) => {
              if (model.type1 === '') {
                return `用户：${this.renderUsername(
                  model.mobile
                )}(${this.renderMobile(model.mobile)})`
              }
              return `${this.userName}的${
                model.type1
              }<br />${this.renderUsername(model.mobile)}(${this.renderMobile(
                model.mobile
              )})`
            },
            offset: 100,
          },
          'drag-canvas',
          'drag-node',
          'lasso-select',
          'click-select',
          'activate-relations',
        ],
        fisheyeMode: [],
      },
      fitView: true,
      fitViewPadding: 50,
      layout: {
        type: 'force',
        nodeSpacing: 100,
        minMovement: 0.01,
        maxIteration: 5000,
        damping: 0.99,
        gpuEnabled: true,
        linkDistance: (d: any) => {
          // console.log(d);
          if (d.target.type1 === '球友') {
            return 1000
          } else if (d.target.type1 === '球友的球友') {
            return 600
          } else if (d.target.type1 === '球友的社群球友') {
            return 400
          } else if (d.target.type1 === '社群球友') {
            return 800
          }

          return 1200
        },
        preventOverlap: true,
        // maxLevelDiff: 0.5,
        // sortBy: "pos",
        // workerEnabled: true,
      },
      animate: true,
      // groupByTypes: false,
      defaultNode: {
        style: {
          // fill: '#steelblue',
          // stroke: '#eaff8f',
          lineWidth: 5,
        },
        labelCfg: {
          position: 'bottom',
          style: {
            fill: '#fff',
          },
        },
      },
      defaultEdge: {
        // type: 'arc',
        type: 'custom-line',
        size: 1,
        color: '#999',

        style: {
          // opacity: 0.2,
          endArrow: true,
        },
        labelCfg: {
          autoRotate: true,
          refY: -10,
        },
      },
      // nodeStateStyles: {
      //   active: {
      //     opacity: 1,
      //   },
      //   inactive: {
      //     opacity: 0.2,
      //   },
      // },
      // edgeStateStyles: {
      //   active: {
      //     opacity: 1,
      //   },
      //   inactive: {
      //     opacity: 0.2,
      //   },
      // },
    })

    this.graph.on('keydown', (ev: any) => {
      const code = ev.key
      if (!code) {
        return
      }

      if (code.toLowerCase() === 'shift') {
        this.shiftKeydown = true
      } else {
        this.shiftKeydown = false
      }
    })

    this.graph.on('keyup', (ev: any) => {
      const code = ev.key

      if (!code) {
        return
      }

      if (code.toLowerCase() === 'shift') {
        this.shiftKeydown = false
      }
    })

    this.graph.on('node:click', (ev: any) => {
      if (!this.shiftKeydown) {
        this.clearFocusItemState(this.graph)
      } else {
        this.clearFocusEdgeState(this.graph)
      }

      const { item } = ev

      // console.log(item)

      this.graph.setItemState(item, 'focus', true)

      if (!this.shiftKeydown) {
        // 将相关边也高亮
        const relatedEdges = item.getEdges()
        // console.log(relatedEdges)
        relatedEdges.forEach((edge: any) => {
          this.graph.setItemState(edge, 'running', true)
        })
      }
    })

    this.loading = true

    const res1 = await this.$store.dispatch(`datav/singlePlayerData`, {
      user_id: this.userId,
    })
    if (res1 && res1.Data) {
      this.num1 = res1.Data.ccount1
      this.num2 = res1.Data.ccount2
      this.num3 = res1.Data.ccount3
      this.num4 = res1.Data.ccount4
    }

    const res = await this.$store.dispatch(`datav/singlePlayer`, {
      user_id: this.userId,
    })
    if (res && res.Data) {
      const nodes: any[] = []
      const edges: any[] = []

      const qiuyou: string[] = []

      const tempArr: string[] = []

      let rainTxt = ''

      for (const obj of res.Data) {
        if (obj.type === '球友') {
          // this.num1++;
          qiuyou.push(obj.mobile)
        } else if (obj.type === '社群球友') {
          // this.num2++;
        } else if (obj.type === '球友的球友') {
          // this.num3++;
        }
        if (
          _.findIndex(nodes, (o: any) => {
            return o.mobile === obj.mobile
          }) === -1
        ) {
          let size = 120
          if (obj.type === '球友') {
            size = 100
          } else if (obj.type === '社群球友') {
            size = 80
          } else if (obj.type === '球友的球友') {
            size = 60
          } else if (obj.type === '球友的社群球友') {
            size = 40
          }

          const color = this.categoryColor(obj)

          // console.log(color)

          rainTxt += ` ${this.renderUsername(obj.mobile)}`

          nodes.push({
            id: obj.mobile,
            // x: 500,
            // y: 500,
            // x: Math.random() * window.innerWidth,
            // y: Math.random() * window.innerHeight,
            username: this.renderUsername(obj.mobile),
            mobile: obj.mobile,
            label: obj.type === '' ? this.renderUsername(obj.mobile) : '',
            type1: obj.type,
            //   type: "image",
            //   img: `https://data.taisam.cn${obj.avatar}`,
            //   type: obj.type,
            //   comboId: obj.type,
            size: size,
            // pos: obj.type === "" ? 10000 : Number(obj.id) % 100,
            style: {
              fill: color.mainFill,
              stroke: color.mainStroke,
            },
            stateStyles: {
              active: {
                fill: color.activeFill,
                stroke: color.activeStroke,
                shadowColor: color.activeStroke,
              },
              inactive: {
                fill: color.inactiveFill,
                stroke: color.inactiveStroke,
              },
              // selected: {
              //   fill: color.selectedFill,
              //   stroke: color.selectedStroke,
              //   shadowColor: color.selectedStroke,
              // },
              highlight: {
                fill: color.highlightFill,
                stroke: color.highlightStroke,
              },
              disable: {
                fill: color.disableFill,
                stroke: color.disableStroke,
              },
            },
            // type: 'image',
            icon: {
              show: true,
              img: this.renderImage(obj.avatar),
              width: size / 1.5,
              height: size / 1.5,
            },
            // img: this.renderImage(obj.avatar),
            // size: 100,
            labelCfg: {
              position: 'bottom',
              style: {
                fill: '#fff',
              },
            },
            clipCfg: {
              show: true,
              type: 'circle',
              r: size / 2,
            },
          })
        }

        if (obj.source && obj.target) {
          if (
            _.indexOf(tempArr, `edge${obj.target}-${obj.source}`) === -1 &&
            _.indexOf(tempArr, `edge${obj.source}-${obj.target}`) === -1
          ) {
            tempArr.push(`edge${obj.source}-${obj.target}`)

            const edge: any = {
              id: `edge${obj.source}-${obj.target}`,
              // type: 'custom-line',
              source: obj.source,
              target: obj.target,
              curveOffset: 20,
            }

            if (
              _.indexOf(qiuyou, obj.source) !== -1 &&
              _.indexOf(qiuyou, obj.target) !== -1
            ) {
              // 都是球友
              // edge.style = {
              // stroke: 'red',
              // opacity: 0.3,
              // }
            }

            edges.push(edge)
          }
        }
      }

      // console.log(
      //   JSON.stringify({
      //     nodes,
      //     edges
      //   })
      // );

      // console.log(nodes)

      this.graph.data({
        nodes,
        edges,
      })
      this.graph.render()

      this.graph.get('canvas').set('supportCSSTransform', true)

      // @ts-ignore
      rain(this.$refs.canvasRain, rainTxt, '', 30)
    }

    this.loading = false

    this.infoBox = true

    // graph.positionsAnimate();
  }

  async mounted() {
    this.$nextTick(() => {
      this.loadG6()
    })
  }

  clearFocusItemState(graph: any) {
    if (!graph) return
    this.clearFocusNodeState(graph)
    this.clearFocusEdgeState(graph)
  }

  // 清除图上所有节点的 focus 状态及相应样式
  clearFocusNodeState(graph: any) {
    const focusNodes = graph.findAllByState('node', 'focus')
    focusNodes.forEach((fnode: any) => {
      graph.setItemState(fnode, 'focus', false) // false
    })
  }

  // 清除图上所有边的 focus 状态及相应样式
  clearFocusEdgeState(graph: any) {
    const focusEdges = graph.findAllByState('edge', 'running')
    focusEdges.forEach((fedge: any) => {
      graph.setItemState(fedge, 'running', false)
    })
  }

  findPath() {
    // console.log(this.graph)
    if (!this.graph || this.graph.get('destroyed')) return false
    // console.log('123')
    // console.log(this.graph.get('data'))

    const selectedNodes = this.graph.findAllByState('node', 'selected')

    if (selectedNodes.length !== 2) {
      alert('Please Select only Two Nodes two Find the Path!')
      return
    }

    // console.log(selectedNodes)

    // this.clearFocusItemState(this.graph)

    // @ts-ignore
    const { findShortestPath } = G6.Algorithm

    const { path } = findShortestPath(
      this.graph.get('data'),
      selectedNodes[0].getID(),
      selectedNodes[1].getID()
    )
    // console.log(path)

    this.graph.getEdges().forEach((edge: any) => {
      const edgeModel = edge.getModel()
      const source = edgeModel.source
      const target = edgeModel.target

      const sourceInPathIdx = path.indexOf(source)
      const targetInPathIdx = path.indexOf(target)
      if (sourceInPathIdx === -1 || targetInPathIdx === -1) return
      if (Math.abs(sourceInPathIdx - targetInPathIdx) === 1) {
        edge.toFront()
        this.graph.setItemState(edge, 'running', true)
      }
    })

    path.forEach((id: any) => {
      const pathNode = this.graph.findById(id)
      pathNode.toFront()
      this.graph.setItemState(pathNode, 'focus', true)
    })
  }
}
