













import { Vue, Component, Mixins } from 'vue-property-decorator'

import MyCommon from '@/mixins/common'

// @ts-ignore
import MyDvTitle from '$ui/dv/packages/my-dv-title'
// @ts-ignore
import MyDvMenu from '$ui/dv/packages/my-dv-menu'
// @ts-ignore
import MyDvBox from '$ui/dv/packages/my-dv-box'
// @ts-ignore
import MyDvIcon from '$ui/dv/packages/my-dv-icon'
// @ts-ignore
import MyDvNumber from '$ui/dv/packages/my-dv-number'
// @ts-ignore
import MyDvText from '$ui/dv/packages/my-dv-text'
// @ts-ignore
import MyDvBorder6 from '$ui/dv/packages/my-dv-border6'

import Graph from './graph.vue'

@Component({
  components: {
    MyDvTitle,
    MyDvMenu,
    MyDvBox,
    MyDvIcon,
    MyDvNumber,
    MyDvText,
    MyDvBorder6,
    Graph,
  },
})
export default class extends Mixins(MyCommon) {}
